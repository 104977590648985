<template>
  <div class="question-card">
    <v-card class="mb-5" elevation="2">
      <v-card-title
        >{{ category.id }}.{{ question.id }}
        {{ question.question }}
      </v-card-title>

      <v-card-actions>
        <v-btn icon @click="active = !active" color="primary">
          <v-icon>{{ active ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="active">
          <v-divider></v-divider>

          <v-card-text>
            <p v-if="question.answer">
              <span
                v-for="(line, lineNumber) of question.answer.split('\n')"
                :key="lineNumber"
              >
                {{ line }}<br />
              </span>
            </p>
            <ul v-if="question.answers">
              <li v-for="answer in question.answers" :key="answer">
                {{ answer }}
              </li>
            </ul>
          </v-card-text>

          <v-img
            v-if="question.image"
            :src="'img/' + question.image"
            contain
          ></v-img>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["question", "category"],
  data: () => {
    return {
      active: false,
    };
  },
};
</script>
