import axios from "axios";

export default {
  async getCategories() {
    let res = await axios.get("/data/categories.json");
    return res.data;
  },
  async getQuestionsByCategoryId(categoryId) {
    let res = await axios.get("/data/questions/" + categoryId + ".json");
    return res.data;
  },
};
