<template>
  <div class="category pa-5" :key="category.id">
    <h1 class="mb-10 primary--text">{{ category.title }}</h1>
    <div v-for="question in questions" :key="question.id">
      <QuestionCard :question="question" :category="category" />
    </div>
  </div>
</template>
<script>
import QuestionCard from "../components/QuestionCard";
import QuestionaireService from "../services/QuestionaireService";
import Vue from "vue";

export default {
  name: "Category",
  components: {
    QuestionCard,
  },
  methods: {
    async getCategoriesData() {
      // Use the eventService to call the getEventSingle() method
      QuestionaireService.getCategories().then((categories) => {
        Vue.set(this, "categories", categories);
        const ID = Number(this.$route.params.id);
        this.category = this.categories.find((category) => category.id === ID);
      });
    },
    async getQuestionsData() {
      // Use the eventService to call the getEventSingle() method
      QuestionaireService.getQuestionsByCategoryId(this.$route.params.id).then(
        (questions) => {
          Vue.set(this, "questions", questions);
        }
      );
    },
  },
  watch: {
    category() {
      this.getCategoriesData();
      this.getQuestionsData(this.category.id);
    },
  },
  data: () => {
    return {
      categories: {},
      category: {},
      questions: {},
    };
  },
  created() {
    this.getCategoriesData();
    this.getQuestionsData(this.category.id);
  },
};
</script>
